import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/ulrika-christina-bore-norrman.jpg";
  const fullName = `Ulrika Christina Bore (Norrman)`;
  const zoomUrl = "";
  // "https://us02web.zoom.us/j/88063155357?pwd=bEVmQUVpYnkzWVVEVDlZbTl3UXNsQT09";
  const zoomPhoneNumber = ""; // "(647) 374-4685";
  const zoomMeetingId = ""; // "880 6315 5357";
  const zoomMeetingPasscode = ""; // "951148";
  const birthDate = new Date(1943, 8 - 1, 4);
  const deathDate = new Date(2021, 1 - 1, 26);
  const birthDeathFooter = "";
  const memorialDate = ""; // new Date(2021, 2 - 1, 20);
  const memorialTime = ""; // "11am PST";
  const inviteText = `As a family, we did not want Covid to stop us from giving Mom a proper send off. Please join us in a virtual gathering to celebrate and honour the life of our beloved Mother.`;
  const inviteChild = (
    <>
      <h2 sx={{ marginTop: ["30px", "40px"] }}>
        <span>Memorial Details</span>
      </h2>
      <div>Postponed until further notice</div>
    </>
  );
  const obituary = `There is much to be said about this beautiful, amazing human. Rather than write a list of all the things that made Mom wonderful, we decided use what our brother wrote about our experience on the day of her passing. Mom’s unexpected gift to us that day expresses her essence perfectly.
  \nMom passed at 5:50 am in her own bed. Still and beautiful. Peaceful. Her day was full of goodbyes and tears. So many tears. A lot of one-sided conversations and unanswerable questions.
  \nAt lunch time, an angel disguised as a nurse stopped by to help get mom dressed for her next adventure. Mom had already picked out what she was going to wear for the next part of her journey. Ever the stylish one. She stayed with us for the rest of the day. So beautiful and serene. Many memories were shared. Stories told that brought laughter and tears. All the while there was a feeling of dread. Moms ride was coming at 9 in the evening and none of us were looking forward to it. 9 o’clock came and we watched the hearse arrive.
  \nThat’s when two more angels showed up. One was disguised as a giant of a man and the other as a young lady. Angels are so clever. Only a few of us could watch the next part to happen upstairs in Mom’s bedroom. The part where Mom’s stillness was to be disturbed. But her beauty, nothing on earth could disturb that.
  \nUnbeknownst to us all gathered downstairs, Mom was about to kick dread’s butt out of the house. Upstairs, the angels started laughing. This was a cue from Mom and we all started laughing. It was such a beautiful wonderful thing, this laughter. Mom, we just gotta say, so well played. The laughter stopped and dread had just met my mom. It didn’t stand a chance.
  \nTwo weeks earlier, mom had picked what she was to wear this day. She had ordered a pair of skimpy lace panties from Knotty Knickers and a sheer, see-through nighty. Like super sexy. When we all heard the angels laugh, we all knew what the laugh was about and, at that moment we all knew mom had this part figured out perfectly.
  \nWe can hear her laughing even now as this is being written. Mom told us all before she left that she was going to come to each of us to let us know she’s still around. We hear you laughing mom. Thank you.
  \nIn lieu of flowers please just love one another.`;
  const storiesRecordUrl = "https://stories.afterword.com/fn95x1vvj";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rz24a9spjnahba4p8f938rdkzb46f9mfjj8gnke0";
  const recordingEmbedUrl = "";
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
      dropboxUrl={dropboxUrl}
    >
      <p>
        For those family members who wish to have a remembrance of Mom, we will
        gift an ash filled pendant. If you wish to have one please go to{" "}
        <a
          href="https://www.etsy.com/ca/shop/Ashes2AshesMemorials?ref=simple-shop-header-name&listing_id=260925880"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ashes2AshesMemorials
        </a>{" "}
        and make your choice up to a value of $45. Once your choice is made,
        send the link or a picture of your choice to{" "}
        <a
          href="mailto:christinaashjewelry@shaw.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          christinaashjewelry@shaw.ca
        </a>{" "}
        . Please include your name and address. If you are not family and wish
        to have a pendant, follow the instructions above and we will discuss
        payment later. Thank you.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
